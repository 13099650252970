(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react-dom/server"));
	else if(typeof define === 'function' && define.amd)
		define(["react-dom/server"], factory);
	else if(typeof exports === 'object')
		exports["audi-feature-hub-integrator"] = factory(require("react-dom/server"));
	else
		root["audi-feature-hub-integrator"] = factory(root["react-dom/server"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__8250__) => {
return 